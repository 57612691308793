import React, { useEffect, useState } from "react";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";

interface DisclaimerPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<{ type: actions }> | any;
  zeroToFirstStepAction: () => void;
}

const DisclaimerContent = (props: DisclaimerPropsType) => {
  const { state, dispatch, zeroToFirstStepAction } = props;
  const siteName = process.env.REACT_APP_SITE_NAME || "Euromedic";
  const [DisclaimerComponent, setDisclaimerComponent] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const loadDisclaimerComponent = async () => {
      const component = await import(`./Disclaimer${siteName}`)
      setDisclaimerComponent(() => component.default);
    };
    loadDisclaimerComponent();
  }, [siteName]);

  if (!DisclaimerComponent) {
    return <div>Učitava se disklejmer...</div>;
  }

  return <DisclaimerComponent state={state} dispatch={dispatch} zeroToFirstStepAction={zeroToFirstStepAction} />;
};

export default DisclaimerContent;