import LocationsContent from "./LocationsContent";

interface LocationsPropsType {
  additionalClass?: string;
}

export default function Locations(props: LocationsPropsType) {
  const { additionalClass } = props;

  return (
    <div className={`col-12 xl:col-3 locations-layout ${additionalClass ?? ""}`}>
      <LocationsContent />
    </div>
  );
}
