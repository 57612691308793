import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./App.scss";
import reportWebVitals from "./reportWebVitals";
import Store from "./Store";
import { BrowserRouter } from "react-router-dom";

const siteName = process.env.REACT_APP_SITE_NAME || "Euromedic";
const faviconMap: { [key: string]: string } = {
  Euromedic: "/favicon-Euromedic.ico",
  Suvajac: "/favicon-Suvajac.ico",
};

// Get the favicon path based on the site name
const faviconPath = faviconMap[siteName] || faviconMap["Euromedic"];

// Change the favicon
const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
if (link) {
  link.href = faviconPath;
} else {
  // If no link found, create a new one
  const newLink = document.createElement("link");
  newLink.rel = "icon";
  newLink.href = faviconPath;
  document.head.appendChild(newLink);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Store>
      <App />
    </Store>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
