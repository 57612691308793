import React, { useEffect, useState } from "react";

const LocationsContent = () => {
  const siteName = process.env.REACT_APP_SITE_NAME || "Euromedic";
  const [LocationsComponent, setLocationsComponent] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const loadLocationsComponent = async () => {
      const component = await import(`./Locations${siteName}`)
      setLocationsComponent(() => component.default);
    };
    loadLocationsComponent();
  }, [siteName]);

  if (!LocationsComponent) {
    return <div>Učitava se sadržaj...</div>;
  }

  return <LocationsComponent />;
};

export default LocationsContent;
