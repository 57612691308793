const Images = {
  SCHEDULE_ICON: require("../images/action.svg").default,
  LOGO: require(`../images/logo-${process.env.REACT_APP_SITE_NAME}.svg`).default,
  CHECK_SUCCESS_ICON: require("../images/check.svg").default,
  ICON_ERROR: require("../images/error.svg").default,
  LOGO_FOOTER: require(`../images/logo-footer-${process.env.REACT_APP_SITE_NAME}.svg`).default,
  ALLSECURE_ICON: require("../images/allsecure.svg").default,
  DINACARD_ICON: require("../images/dinacard.svg").default,
  VISA_ICON: require("../images/visa.svg").default,
  VISA_ELECTRON: require("../images/visaelectron.svg").default,
  VISA_SECURE: require("../images/visa_secure.svg").default,
  MAESTRO_ICON: require("../images/maestro.svg").default,
  MASTER_ICON: require("../images/master.svg").default,
  BANCA_INTESA_ICON: require("../images/bib.svg").default,
  AMEX_ICON: require("../images/amex.svg").default,
  MASTERCARD_ID_CHECK_ICON: require("../images/mc_idcheck.svg").default,
};

export default Images;
