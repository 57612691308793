import { Checkbox } from "primereact/checkbox";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";

interface PrivacyTemplatePropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  captchaRef: React.MutableRefObject<any>;
}

const PrivacyTemplate = (props: PrivacyTemplatePropsType) => {
  const { state, dispatch, invalidFields, setInvalidFields, captchaRef } = props;
  const siteName = process.env.REACT_APP_SITE_NAME;
  let documentPath = "";

  if (siteName === "Euromedic") {
    documentPath = "/documents/pravilaEuromedic.pdf";
  } else if (siteName === "Suvajac") {
    documentPath = "/documents/pravilaSuvajac.pdf";
  } else {
    documentPath = "/documents/pravilaEuromedic.pdf";
  }

  return (
    <div className="mb-5 relative flex flex-row justify-content-center align-items-center">
      <Checkbox
        inputId="patient-accept-privacy-policy"
        checked={state?.privacyPolicy}
        onChange={(e) => {
          dispatch({ type: actions.PRIVACY_POLICY, value: e.checked });
          setInvalidFields((prev) => ({ ...prev, patientPrivacyPolicy: false }));
          captchaRef?.current.execute();
        }}
        className={invalidFields?.patientPrivacyPolicy && "p-invalid"}
      />
      <label htmlFor="patient-accept-privacy-policy" className="cursor-pointer p-checkbox-label ml-2">
        {Labels.LABEL_PRIVACY_POLICY_CHECKBOX_1}{" "}
        <a href={documentPath} target="_blank" rel="noreferrer">
          {Labels.LABEL_PRIVACY_POLICY_CHECKBOX_2}
        </a>
        {Labels.SPECIAL_CHAR_REQUIRED}
      </label>
    </div>
  );
};

export default PrivacyTemplate;
