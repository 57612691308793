import SpecializationContent from "./SpecializationContent";

interface SpecializationPropsType {
  additionalClass?: string;
}

export default function Specialization(props: SpecializationPropsType) {
  const { additionalClass } = props;

  return (
    <div className={`specializations-layout col-12 xl:col-3 ${additionalClass ?? ""}`}>
      <SpecializationContent />
    </div>
  );
}
