import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import SchedulerSearchCreateDto from "../../model/SchedulerSearchCreateDto";
import { addSrLocal, CALENDAR_DATE_FORMAT, createCurrentDateWithTime, DATE_FORMAT2, formatDate, numberFormat, STEP_MINUTE, TIME_FORMAT, useEffectOnce } from "../../infrastructure/Utils";
import ObaveznoPolje from "../obavezno-polje/ObaveznoPolje";
import SkeletonInputItem from "../skeleton/SkeletonInputItem";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import moment from "moment";
import { isMobile, isTablet } from "react-device-detect";
import Locations from "../static-content/Locations";
import Specialization from "../static-content/Specialization";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import SearchLogical, { SearchLogicalType } from "./SearchLogical";
import { RefObject, useEffect } from "react";
import { useBeforeunload } from "react-beforeunload";
import ProcedureGroupReadDto from "../../model/ProcedureGroupReadDto";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

interface SearchPropsType {
  appointmentsSearch: SchedulerSearchCreateDto | undefined;
  setAppointmentsSearch: React.Dispatch<React.SetStateAction<any>>;
  invalidFields: { [field: string]: boolean | any } | undefined;
  setInvalidFields: React.Dispatch<React.SetStateAction<{ [field: string]: boolean | any } | undefined>>;
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>;
  getLocationsDoctorsForSpecialtyAndProvider: (specialtyID: number, procedureID: number) => void;
  getProcedures: (specialtyID: number, procedureGroupId?: number) => void;
  sessionCodeRef: RefObject<string>;
}

export default function Search(props: SearchPropsType) {
  const { appointmentsSearch, setAppointmentsSearch, invalidFields, setInvalidFields, state, dispatch, getLocationsDoctorsForSpecialtyAndProvider, getProcedures, sessionCodeRef } = props;
  const { fetchFilterData, updateDoctorsOrLocation, filterDoctorsByLocation, filterLocationByDoctors, checkWarning, fetchSpecializations }: SearchLogicalType = SearchLogical(
    state,
    dispatch,
    appointmentsSearch,
    setAppointmentsSearch
  );
  addSrLocal();

  useEffectOnce(() => {
    fetchFilterData(sessionCodeRef?.current!);
    checkWarning(sessionCodeRef.current!);
  });

  useEffect(() => {
    if (state?.selectedGroup) {
      fetchSpecializations(sessionCodeRef?.current!, state?.selectedGroup);
      if (appointmentsSearch?.specialtyIdList?.length) getProcedures(appointmentsSearch!.specialtyIdList![0], state?.selectedGroup);
    }
    // eslint-disable-next-line
  }, [state?.selectedGroup]);

  const parseValue = (v: any) => (v ? new Date(v) : undefined);
  const tooltipShowEvent = isMobile || isTablet ? "click" : "mouseenter";
  const tooltipHideEvent = isMobile || isTablet ? "click" : "mouseleave";
  const tooltipPosition = isMobile ? "mouse" : "top";

  useBeforeunload(() => "");

  return (
    <>
      <Locations additionalClass={"pt-4 xl:pt-8"} />
      <div className="search-layout col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6 xl:pt-8">
        <div className="procedure-group flex flex-row justify-content-center flex-wrap mb-5">
          {state?.procedureGroupsLoading ? (
            <ProgressSpinner />
          ) : (
            state?.procedureGroups?.map((group: ProcedureGroupReadDto) => (
              <Button
                key={group.id}
                label={group.name}
                onClick={() => {
                  if (state?.selectedGroup === group.id) {
                    dispatch({ type: actions.SET_SELECTED_GROUP, value: undefined });
                    fetchSpecializations(sessionCodeRef?.current!);
                    if (appointmentsSearch!.specialtyIdList?.length) getProcedures(appointmentsSearch!.specialtyIdList![0]);
                  } else {
                    dispatch({ type: actions.SET_SELECTED_GROUP, value: group.id });
                  }
                }}
                className={`group-item-btn ${state?.selectedGroup === group.id ? "selected" : ""}`}
              />
            ))
          )}
        </div>
        <div className="flex flex-row flex-wrap justify-content-between">
          <div className="flex px-0 pb-5 align-items-center md:justify-content-center date-item">
            <div className="relative">
              <div className="flex align-items-center mb-2">
                <div>{Labels.LABEL_DATUM_OD_PLACEHOLDER + Labels.SPECIAL_CHAR_REQUIRED}</div>
                <i
                  className="pi pi-info-circle date-from ml-2"
                  data-pr-tooltip={Labels.LABEL_SEARCH_DATE_FROM_DESCRIPTION}
                  data-pr-position={tooltipPosition}
                  data-pr-showevent={tooltipShowEvent}
                  data-pr-hideevent={tooltipHideEvent}
                />
                <Tooltip target=".date-from" />
              </div>
              <Calendar
                dateFormat={CALENDAR_DATE_FORMAT}
                showIcon
                placeholder={Labels.LABEL_DATUM_OD_PLACEHOLDER}
                value={parseValue(appointmentsSearch?.fromDate)}
                onChange={(e) => {
                  setAppointmentsSearch({
                    ...appointmentsSearch!,
                    fromDate: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined, DATE_FORMAT2),
                  });
                  setInvalidFields((prev) => ({ ...prev, fromDate: false }));
                }}
                className={invalidFields?.specialtyIdList && "p-invalid"}
                inputClassName="pr-0"
                minDate={new Date()}
                disabled={state?.appointmentsLoading}
                locale={"sr"}
              />
              {invalidFields?.fromDate && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
            <div className="mx-2 mt-4">-</div>
            <div className="relative">
              <div className="flex align-items-center mb-2">
                <div>{Labels.LABEL_DATUM_DO_PLACEHOLDER}</div>
                <i
                  className="pi pi-info-circle date-to ml-2"
                  data-pr-tooltip={Labels.LABEL_SEARCH_DATE_TO_DESCRIPTION}
                  data-pr-position={tooltipPosition}
                  data-pr-showevent={tooltipShowEvent}
                  data-pr-hideevent={tooltipHideEvent}
                />
                <Tooltip target=".date-to" />
              </div>
              <Calendar
                dateFormat={CALENDAR_DATE_FORMAT}
                showIcon
                placeholder={Labels.LABEL_DATUM_DO_PLACEHOLDER}
                value={parseValue(appointmentsSearch?.toDate)}
                onChange={(e) => {
                  setAppointmentsSearch({
                    ...appointmentsSearch!,
                    toDate: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined, DATE_FORMAT2),
                  });
                  setInvalidFields((prev) => ({ ...prev, toDate: false }));
                }}
                className={invalidFields?.specialtyIdList && "p-invalid"}
                inputClassName="pr-0"
                minDate={moment(appointmentsSearch?.fromDate).toDate()}
                disabled={state?.appointmentsLoading}
                locale={"sr"}
              />
              {invalidFields?.toDate && <ObaveznoPolje text={Labels.LABEL_INVALID_DATE_TO} className="dateTo-error" />}
            </div>
          </div>
          <div className="flex px-0 pb-5 align-items-center md:justify-content-center time-item">
            <div className="relative">
              <div className="flex align-items-center mb-2">
                <div>{Labels.LABEL_VREME_OD_PLACEHOLDER + Labels.SPECIAL_CHAR_REQUIRED}</div>
                <i
                  className="pi pi-info-circle time-from ml-2"
                  data-pr-tooltip={Labels.LABEL_SEARCH_TIME_FROM_DESCRIPTION}
                  data-pr-position={tooltipPosition}
                  data-pr-showevent={tooltipShowEvent}
                  data-pr-hideevent={tooltipHideEvent}
                />
                <Tooltip target=".time-from" />
              </div>
              <Calendar
                icon="pi pi-clock"
                timeOnly
                showIcon
                placeholder={Labels.LABEL_VREME_OD_PLACEHOLDER}
                value={appointmentsSearch?.fromTime ? createCurrentDateWithTime(appointmentsSearch?.fromTime) : undefined}
                stepMinute={STEP_MINUTE}
                onChange={(e) => {
                  setAppointmentsSearch({
                    ...appointmentsSearch!,
                    fromTime: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined, TIME_FORMAT),
                  });
                  setInvalidFields((prev) => ({ ...prev, fromTime: false }));
                }}
                className={invalidFields?.specialtyIdList && "p-invalid"}
                disabled={state?.appointmentsLoading}
              />
              {invalidFields?.fromTime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
            <div className="mx-2 mt-4">-</div>
            <div className="relative">
              <div className="flex align-items-center mb-2">
                <div>{Labels.LABEL_VREME_DO_PLACEHOLDER + Labels.SPECIAL_CHAR_REQUIRED}</div>
                <i
                  className="pi pi-info-circle time-to ml-2"
                  data-pr-tooltip={Labels.LABEL_SEARCH_TIME_TO_DESCRIPTION}
                  data-pr-position={tooltipPosition}
                  data-pr-showevent={tooltipShowEvent}
                  data-pr-hideevent={tooltipHideEvent}
                />
                <Tooltip target=".time-to" />
              </div>
              <Calendar
                icon="pi pi-clock"
                timeOnly
                showIcon
                placeholder={Labels.LABEL_VREME_DO_PLACEHOLDER}
                value={appointmentsSearch?.toTime ? createCurrentDateWithTime(appointmentsSearch?.toTime) : undefined}
                stepMinute={STEP_MINUTE}
                onChange={(e) => {
                  setAppointmentsSearch({
                    ...appointmentsSearch!,
                    toTime: formatDate(e.target.value !== null ? (e.target.value as Date) : undefined, TIME_FORMAT),
                  });
                  setInvalidFields((prev) => ({ ...prev, toTime: false }));
                }}
                className={(invalidFields?.toTime || invalidFields?.toTimeInvalid) && "p-invalid"}
                disabled={state?.appointmentsLoading}
              />
              {invalidFields?.toTime && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
              {invalidFields?.toTimeInvalid && <ObaveznoPolje text={Labels.LABEL_INVALID_TIME_TO} className="dateTo-error" />}
            </div>
          </div>
        </div>
        <div className="w-full mb-5">
          {state?.filterDataLoading ? (
            <SkeletonInputItem />
          ) : (
            <div className="relative">
              <div className="flex align-items-center mb-2">
                <div>{Labels.LABEL_SPECIJALIZACIJA + Labels.SPECIAL_CHAR_REQUIRED}</div>
                <i
                  className="pi pi-info-circle specialisations ml-2"
                  data-pr-tooltip={Labels.LABEL_SEARCH_SPECIALISATIONS_DESCRIPTION}
                  data-pr-position={tooltipPosition}
                  data-pr-showevent={tooltipShowEvent}
                  data-pr-hideevent={tooltipHideEvent}
                />
                <Tooltip target=".specialisations" />
              </div>
              <Dropdown
                placeholder={Labels.LABEL_CHOOSE_SPECIJALIZACIJA}
                options={state?.specialisations}
                optionValue={"id"}
                optionLabel={"name"}
                value={appointmentsSearch?.specialtyIdList?.length ? appointmentsSearch.specialtyIdList[0] : null}
                filter
                onChange={(e) => {
                  setAppointmentsSearch({
                    ...appointmentsSearch,
                    specialtyIdList: [e.target.value],
                    providerList: [{ providerId: appointmentsSearch?.providerList[0].providerId, locationIdList: [], doctorList: [] }],
                  });
                  dispatch({ type: actions.APPOINTMENT_CHANGE, appointment: { ...state.appointment!, fieldName: "procedure", value: undefined } });
                  dispatch({ type: actions.APPOINTMENT_CHANGE, appointment: { ...state.appointment!, fieldName: "procedureFull", value: undefined } });
                  dispatch({ type: actions.APPOINTMENT_CHANGE, appointment: { ...state.appointment!, fieldName: "currency", value: undefined } });
                  dispatch({
                    type: actions.APPOINTMENT_CHANGE,
                    appointment: {
                      ...state.appointment!,
                      fieldName: "frontendInformation",
                      value: {
                        ...state?.appointment?.frontendInformation,
                        procedureName: undefined,
                        procedurePrice: undefined,
                        procedureCurrency: undefined,
                      },
                    },
                  });
                }}
                className={"w-full"}
                disabled={state?.appointmentsLoading}
                onHide={() => {
                  appointmentsSearch?.specialtyIdList?.length && getProcedures(appointmentsSearch!.specialtyIdList![0], state?.selectedGroup);
                }}
              />
              {invalidFields?.specialtyIdList && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
          )}
        </div>
        <div className="w-full mb-5">
          {state?.filterDataLoading ? (
            <SkeletonInputItem />
          ) : (
            <div className="relative">
              <div className="flex align-items-center mb-2">
                <div>{Labels.LABEL_USLUGE + Labels.SPECIAL_CHAR_REQUIRED}</div>
                <i
                  className="pi pi-info-circle specialisations ml-2"
                  data-pr-tooltip={Labels.LABEL_SEARCH_SERVICES_DESCRIPTION}
                  data-pr-position={tooltipPosition}
                  data-pr-showevent={tooltipShowEvent}
                  data-pr-hideevent={tooltipHideEvent}
                />
                <Tooltip target=".specialisations" />
              </div>
              <Dropdown
                placeholder={Labels.LABEL_USLUGE_CHOOSE}
                options={state?.procedures}
                optionLabel={"name"}
                itemTemplate={(e) => `${e.name} - ${numberFormat(e.procedurePriceList[0].price, 2, 2)} ${e.procedurePriceList[0].currency.code}`}
                valueTemplate={(e) =>
                  `${e?.name ? e?.name + " -" : Labels.LABEL_USLUGE_CHOOSE} ${e?.procedurePriceList[0]?.price ? numberFormat(e.procedurePriceList[0].price, 2, 2) : ""} ${
                    e?.procedurePriceList[0]?.currency.code ?? ""
                  }`
                }
                value={state?.appointment?.procedureFull}
                onChange={(e) => {
                  dispatch({ type: actions.APPOINTMENT_CHANGE, appointment: { ...state.appointment!, fieldName: "procedure", value: { id: e.target.value.id } } });
                  dispatch({ type: actions.APPOINTMENT_CHANGE, appointment: { ...state.appointment!, fieldName: "procedureFull", value: e.target.value } });
                  dispatch({ type: actions.APPOINTMENT_CHANGE, appointment: { ...state.appointment!, fieldName: "currency", value: { code: e.target.value.procedurePriceList[0].currency.code } } });
                  dispatch({
                    type: actions.APPOINTMENT_CHANGE,
                    appointment: {
                      ...state.appointment!,
                      fieldName: "frontendInformation",
                      value: {
                        ...state?.appointment?.frontendInformation,
                        procedureName: e.target.value.name,
                        procedurePrice: e.target.value.procedurePriceList[0].price,
                        procedureCurrency: e.target.value.procedurePriceList[0].currency.code,
                      },
                    },
                  });
                  setAppointmentsSearch({
                    ...appointmentsSearch,
                    providerList: [{ providerId: appointmentsSearch?.providerList[0].providerId, locationIdList: [], doctorList: [] }],
                  });
                }}
                className={"w-full"}
                disabled={!appointmentsSearch?.specialtyIdList || state?.proceduresLoading || state?.appointmentsLoading}
                dropdownIcon={state?.proceduresLoading ? "pi pi-spinner pi-spin" : "pi pi-chevron-down"}
                emptyMessage={Labels.MESSAGES_NO_RESULT_FOUND}
                onHide={() => {
                  state?.appointment?.procedureFull && getLocationsDoctorsForSpecialtyAndProvider(appointmentsSearch!.specialtyIdList![0], state?.appointment?.procedureFull?.id);
                }}
              />
              {invalidFields?.specialtyIdList && <ObaveznoPolje text={Labels.LABEL_REQUIRED_FIELD} />}
            </div>
          )}
        </div>
        <div className="w-full mb-5">
          {state?.filterDataLoading ? (
            <SkeletonInputItem />
          ) : (
            <>
              <div className="flex align-items-center mb-2">
                <div>{Labels.LABEL_LOKACIJA}</div>
                <i
                  className="pi pi-info-circle locations ml-2"
                  data-pr-tooltip={Labels.LABEL_SEARCH_LOCATIONS_DESCRIPTION}
                  data-pr-position={tooltipPosition}
                  data-pr-showevent={tooltipShowEvent}
                  data-pr-hideevent={tooltipHideEvent}
                />
                <Tooltip target=".locations" />
              </div>
              <MultiSelect
                className="w-full text-left doctors-locations"
                placeholder={Labels.LABEL_LOKACIJA_SVE}
                options={state?.locationsForSelect?.length ? state?.locationsForSelect : [{ id: 0, name: Labels.MESSAGES_NO_RESULT_FOUND, disabled: true }]}
                optionValue="id"
                optionLabel="name"
                value={appointmentsSearch?.providerList[0]?.locationIdList}
                onChange={(e) => {
                  updateDoctorsOrLocation(e.value, false);
                  filterDoctorsByLocation(e.value);
                }}
                display="chip"
                panelHeaderTemplate={<div className="hidden"></div>}
                disabled={!state?.appointment?.procedureFull || state?.locationsLoading || state?.appointmentsLoading}
                dropdownIcon={state?.locationsLoading ? "pi pi-spinner pi-spin" : "pi pi-chevron-down"}
              />
            </>
          )}
        </div>
        <div className="w-full mb-4">
          {state?.filterDataLoading ? (
            <SkeletonInputItem />
          ) : (
            <>
              <div className="flex align-items-center mb-2">
                <div>{Labels.LABEL_LEKAR}</div>
                <i
                  className="pi pi-info-circle doctors ml-2"
                  data-pr-tooltip={Labels.LABEL_SEARCH_DOCTORS_DESCRIPTION}
                  data-pr-position={tooltipPosition}
                  data-pr-showevent={tooltipShowEvent}
                  data-pr-hideevent={tooltipHideEvent}
                />
                <Tooltip target=".doctors" />
              </div>
              <MultiSelect
                className="w-full text-left doctors-locations"
                placeholder={
                  !state?.appointment?.procedure?.mandatoryDoctor && !state?.doctorsForSelect?.length && state?.appointment?.procedureFull
                    ? Labels.LABEL_DOCTOR_NOT_MANDATORY_FOR_PROCEDURE
                    : Labels.LABEL_LEKAR_SVI
                }
                optionValue="id"
                optionLabel="name"
                options={state?.doctorsForSelect?.length ? state?.doctorsForSelect : [{ id: 0, name: Labels.MESSAGES_NO_RESULT_FOUND, disabled: true }]}
                value={appointmentsSearch?.providerList[0]?.doctorList}
                onChange={(e) => {
                  updateDoctorsOrLocation(e.value, true);
                  filterLocationByDoctors(e.value);
                }}
                display="chip"
                panelHeaderTemplate={<div className="hidden"></div>}
                disabled={
                  !state?.appointment?.procedureFull || state?.doctorsLoading || state?.appointmentsLoading || (!state?.appointment?.procedureFull?.mandatoryDoctor && !state?.doctorsForSelect?.length)
                }
                dropdownIcon={state?.doctorsLoading ? "pi pi-spinner pi-spin" : "pi pi-chevron-down"}
              />
            </>
          )}
        </div>
        <div className="text-center">{Labels.LABEL_REQUIRED_FIELDS_NOTE}</div>
      </div>
      <Specialization additionalClass={"pt-0 xl:pt-8"} />
    </>
  );
}
