import React, { useEffect, useState } from "react";

const SpecializationContent = () => {
  const siteName = process.env.REACT_APP_SITE_NAME || "Euromedic";
  const [SpecializationComponent, setSpecializationComponent] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const loadSpecializationComponent = async () => {
      const component = await import(`./Specialization${siteName}`)
      setSpecializationComponent(() => component.default);
    };
    loadSpecializationComponent();
  }, [siteName]);

  if (!SpecializationComponent) {
    return <div>Učitava se sadržaj...</div>;
  }

  return <SpecializationComponent />;
};

export default SpecializationContent;
