import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { useNavigate } from "react-router-dom";
import StaticHeader from "../static-header/StaticHeader";
import Images from "../../infrastructure/system/Images";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import Footer from "../footer/Footer";
import { useEffect } from "react";
import { useBeforeunload } from "react-beforeunload";

export default function TransactionError() {
  const navigate = useNavigate();
  const paymentInfo = JSON.parse(localStorage.getItem("paymentInfo")!);
  const siteColors = JSON.parse(process.env.REACT_APP_SITE_COLORS!);
  const siteBackgroundImages = JSON.parse(process.env.REACT_APP_SITE_BACKGROUND_IMAGES!);
  const backgroundImage1Url = `/images/${siteBackgroundImages.backgroundImage1}`;
  const backgroundImage2Url = `/images/${siteBackgroundImages.backgroundImage2}`;

  // brisemo iteme za slucaj da korisnik koristi back button umesto dugme na pocetak
  useEffect(() => {
    return () => {
      localStorage.removeItem("paymentInfo");
      localStorage.removeItem("reservationUUID");
    };
  }, []);

  useBeforeunload(() => {
    localStorage.removeItem("paymentInfo");
    localStorage.removeItem("reservationUUID");
  });

  return (
    <main
      style={
        {
          "--primary-color": siteColors.primaryColor,
          "--header-background-color": siteColors.headerBackgroundColor,
          "--footer-background-color": siteColors.footerBackgroundColor,
          "--procedure-groups-background-color": siteColors.procedureGroupsBackgroundColor,
          "--background-image-1": `url(${backgroundImage1Url})`,
          "--background-image-2": `url(${backgroundImage2Url})`,
          "--procedure-group-selected-background-color": siteColors.procedureGroupSelectedBackgroundColor,
          "--procedure-group-selected-color": siteColors.procedureGroupSelectedColor,
        } as React.CSSProperties
      }>
      <StaticHeader />
      <div className="flex flex-row align-items-center justify-content-center error-layout print">
        <div className="error-info-box text-center col-11 lg:col-10 my-1 md:my-0 xl:col-6 p-5">
          <div className="error-info-box--icon-wrapper text-center mb-5">
            <Image src={Images.ICON_ERROR} />
          </div>
          <div className="error-info-box--title text-center">{Labels.LABEL_TRANSACTION_ERROR_TITLE}</div>
          <div className="flex flex-row justify-content-center mb-5">
            <div className="error-info-box--items">{Labels.LABEL_TRANSACTION_ERROR_MESSAGE}</div>
          </div>
          {paymentInfo?.error && (
            <div className="flex flex-row justify-content-center mb-5">
              <div className="error-info-box--items">{paymentInfo?.error}</div>
            </div>
          )}
          <div className="flex flex-row flex-wrap justify-content-center no-print">
            <Button
              label={Labels.LABEL_SUCCESS_TO_START}
              onClick={() => {
                localStorage.removeItem("paymentInfo");
                localStorage.removeItem("reservationUUID");
                navigate("/");
              }}
              className="border-radius-0"
            />
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
