import React, { useEffect, useState } from "react";

const FooterMenu = () => {
  const siteName = process.env.REACT_APP_SITE_NAME || "Euromedic";
  const [FooterComponent, setFooterComponent] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const loadFooterComponent = async () => {
      const component = await import(`./FooterMenu${siteName}`)
      setFooterComponent(() => component.default);
    };
    loadFooterComponent();
  }, [siteName]);

  if (!FooterComponent) {
    return <div>Učitavaju se linkovi...</div>;
  }

  return <FooterComponent />;
};

export default FooterMenu;
