import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import NotInFunctionPopUpContent from "./NotInFunctionPopUpContent";

interface NotInFunctionPopUpPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<{ type: actions }> | any;
}

export default function NotInFunctionPopUp(props: NotInFunctionPopUpPropsType) {
  const { state, dispatch } = props;

  return <NotInFunctionPopUpContent state={state} dispatch={dispatch} />;
}
