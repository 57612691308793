import { Checkbox } from "primereact/checkbox";
import Labels from "../../infrastructure/system/Labels_sr_Latn_RS";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import ReducerDispatchWithAppointmentDto from "../../model/ReducerDispatchWithAppointmentDto";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";

interface ConditionsTemplatePropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<ReducerDispatchWithAppointmentDto>;
}

const ConditionsTemplate = (props: ConditionsTemplatePropsType) => {
  const { state, dispatch } = props;
  const siteName = process.env.REACT_APP_SITE_NAME;
  let documentPath = "";

  if (siteName === "Euromedic") {
    documentPath = "/documents/usloviEuromedic.pdf";
  } else if (siteName === "Suvajac") {
    documentPath = "/documents/usloviSuvajac.pdf";
  } else {
    documentPath = "/documents/usloviEuromedic.pdf";
  }

  return (
    <div className="text-center">
      <Checkbox
        inputId="patient-accept-privacy-policy"
        checked={state?.conditionsAgreement ?? false}
        onChange={(e) => {
          dispatch({ type: actions.CONDITIONS_AGREEMENT, value: e.checked });
        }}
      />
      <label htmlFor="patient-accept-privacy-policy" className="cursor-pointer p-checkbox-label ml-2">
        {Labels.LABEL_PAYMENT_CONDITIONS_1}{" "}
        <a href={documentPath} target="_blank" rel="noreferrer">
          {Labels.LABEL_PAYMENT_CONDITIONS_2}
        </a>
      </label>
    </div>
  );
};

export default ConditionsTemplate;
