import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";
import DisclaimerContent from "./DisclaimerContent";

interface DisclaimerPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<{ type: actions }> | any;
  zeroToFirstStepAction: () => void;
}

export default function Disclaimer(props: DisclaimerPropsType) {
  const { state, dispatch, zeroToFirstStepAction } = props;

  return <DisclaimerContent state={state} dispatch={dispatch} zeroToFirstStepAction={zeroToFirstStepAction} />;
}
