import { useEffect, useState } from "react";
import { InitialStateType } from "../../infrastructure/system/hooks/wizard-reducer/initialState";
import actions from "../../infrastructure/system/hooks/wizard-reducer/actions";

interface NotInFunctionPopUpContentPropsType {
  state: InitialStateType;
  dispatch: React.Dispatch<{ type: actions }> | any;
}

const NotInFunctionPopUpContent = (props: NotInFunctionPopUpContentPropsType) => {
  const { state, dispatch } = props;
  const siteName = process.env.REACT_APP_SITE_NAME || "Euromedic";
  const [NotInFunctionPopUpContentComponent, setNotInFunctionPopUpContent] = useState<React.ComponentType<any> | null>(null);

  useEffect(() => {
    const loadNotInFunctionPopUpContent = async () => {
      const component = await import(`./NotInFunctionPopUp${siteName}`)
      setNotInFunctionPopUpContent(() => component.default);
    };
    loadNotInFunctionPopUpContent();
  }, [siteName]);

  if (!NotInFunctionPopUpContentComponent) {
    return <div>Učitava se not-in-function popup...</div>;
  }

  return <NotInFunctionPopUpContentComponent state={state} dispatch={dispatch} />;
};

export default NotInFunctionPopUpContent;